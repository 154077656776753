@font-face {
  font-family: 'circular-pro-book';
  src: local('circular-pro-book'), url(./fonts/circular-pro-cufonfonts/lineto-circular-pro-book.ttf) format('truetype');
}

@font-face {
  font-family: 'circular-pro-medium';
  src: local('circular-pro-medium'), url(./fonts/circular-pro-cufonfonts/lineto-circular-pro-medium.ttf) format('truetype');
}

@font-face {
  font-family: 'circular-pro-bold';
  src: local('circular-pro-bold'), url(./fonts/circular-pro-cufonfonts/lineto-circular-pro-bold.ttf) format('truetype');
}

@font-face {
  font-family: 'anton';
  src: local('anton'), url(./fonts/Anton-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: circular-pro-book;
  background-color: #07080c;
  background-color: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes float {
  0% { transform: translateY(0px)  }
  50% { transform: translateY(30px); }
  100% { transform: translateY(0px)  }
 }







@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    filter: drop-shadow(-1px 2px 0 red) drop-shadow(1px -2px 0 blue);
  };
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    filter: drop-shadow(1px -2px 0 red) drop-shadow(-1px 2px 0 blue);

  }
  100% {
    filter: none;
  }
}

@keyframes glitch {
  0% {
    filter: drop-shadow( -1px 2px 0 red) drop-shadow(1px -2px 0 blue);
    transform: translate(var(--glitch-translate));
  }
  2% {
    filter: drop-shadow(1px -2px 0 red) drop-shadow(-1px 2px 0 blue);
  }
  4%, 100% {  filter: none; transform: none; }
}

/* @keyframes glitch {
  0% {
    filter: drop-shadow( -1px 2px 0 red) drop-shadow(1px -2px 0 blue);
    transform: translate(var(--glitch-translate));
  }
  2% {
    filter: drop-shadow(1px -2px 0 red) drop-shadow(-1px 2px 0 blue);
  }
  4%, 100% {  filter: none; transform: none; }
} */